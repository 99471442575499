import React, { useState, useEffect, useRef } from "react";
import { Link } from "gatsby";

import Can from "../Product/Can";
import AmazonButton from "../Global/AmazonButton";
import Viking from "../../assets/images/viking.png";
import hammer from "../../assets/images/hammer.png";
import chalice from "../../assets/images/chalice-gold.png";
import rhino from "../../assets/images/rhino.png";
import strip from "../../assets/images/can-strip.png";

const Hero = ({ title, description, cans }) => {
	const backgroundRef = useRef(null);
	const [distance, setDistance] = useState(false);

	const handleScroll = () => {
		const pageScroll = window.pageYOffset;
		const pageHeight = window.innerHeight;
		const backgroundTop = backgroundRef.current.offsetTop;
		const backgroundHeight = backgroundRef.current.offsetHeight;
		const enterScreen = 0;
		const enterValue = 0;
		const exitScreen = backgroundHeight;
		const exitValue = 100;

		const minView = enterScreen <= pageScroll;
		const maxView = exitScreen < pageScroll;

		const slope = (exitValue - enterValue) / (exitScreen - enterScreen);
		const intercept = enterValue - slope * enterScreen;

		if (minView && !maxView) {
			setDistance(pageScroll * slope + intercept);
		} else if (minView && maxView) {
			setDistance(exitValue);
		} else {
			setDistance(enterValue);
		}
	};

	useEffect(() => {
		if (window.innerWidth > 768) {
			handleScroll();
			window.addEventListener("scroll", handleScroll, {
				passive: true,
			});

			return () => {
				window.removeEventListener("scroll", handleScroll);
			};
		}
	}, []);

	return (
		<div className="hero">
			<h2 dangerouslySetInnerHTML={{ __html: title }} />

			<div className="hero__image-circle">
				<img className="hero__chalice" src={chalice} />
				<img className="hero__head" src={rhino} />
				{cans.map((can, key) => (
					<div className={`hero__can-${key + 1}`} key={`single_can_${key}`}>
						<Link to={can.link}>
							<Can center={can.center} ingredients={can.ingredients} />
						</Link>
					</div>
				))}
			</div>

			<div className="hero__description-wrapper">
				<img className="hero__hammer" src={hammer} alt="hammer" />

				<p dangerouslySetInnerHTML={{ __html: description }} />

				<AmazonButton to="https://www.amazon.com/dp/B0CKRXJL6Q?maas=maas_adg_815CA62E3984F0A5167041CC3D3DDA58_afap_abs&ref_=aa_maas&tag=maas" />
			</div>

			<div className="hero__can-strip">
				<div className="hero__can-strip-track">
					<span>
						<img src={strip} />
					</span>
					<span>
						<img src={strip} />
					</span>
				</div>
			</div>

			<img
				ref={backgroundRef}
				style={{ transform: `translateX(${distance}%)` }}
				className="hero__viking"
				src={Viking}
				alt="Viking"
			/>
		</div>
	);
};

export default Hero;
